import {useThread} from "@/Store/Thread.js";
import {storeToRefs} from "pinia";
import {router, usePage} from "@inertiajs/vue3";
import {loadLanguageAsync, trans} from "laravel-vue-i18n";
import dayjs from "dayjs";
import {useUser} from "@/Store/User.js";
import {getCurrencies} from "@/constants.js";
import {computed, onMounted} from "vue";

export const priceStr = (price) => {
    const formatter = new Intl.NumberFormat('ru', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    });

    return formatter.format(price / 100);
}

export const getPropertyName = (data) => {
    if (data.property.type === 'list') {
        return `${data.property.title}: ${data.property_value?.value}`
    } else if (data.property.type === 'bool') {
        return `${data.property.title}: ${data.value ? trans('Yes') : trans('No')}`
    } else {
        return `${data.property.title}: ${data.value}`
    }
}

// export const getCategoriesString = (data) => {
//     return data.map(item => item.title).join(', ');
// }

export const truncateEmail = (email) => {
    if (email.length > 32) {

        let strData = email.split('@'),
            nameEmail = strData[0],
            typeEmail = strData[1]
        if (nameEmail.length > 14) {
            let half = Math.floor(14 / 2);
            nameEmail = nameEmail.substr(0, half) + "..." + nameEmail.substr(-half);
            let arrTypeEmailStr = typeEmail.split('.')
            if (arrTypeEmailStr[0].length > 6) {
                let half = Math.floor(6 / 2);
                typeEmail = `${arrTypeEmailStr[0].substr(0, half)}...${arrTypeEmailStr[0].substr(-half)}.${arrTypeEmailStr[1]}`;
            }
            return nameEmail + "@" + typeEmail

        } else {
            return email;
        }
    } else {
        return email;
    }

}

export const setMessages = (msgs) => {
    const storeThread = useThread(),
        {messages} = storeToRefs(storeThread)

    messages.value = Object.fromEntries(
        msgs.map(item => [item.id, item])
    );

}

export const listenerNavigate = () => {
    router.reload({
        only: ['messages'],
        onFinish: () => {
            setMessages(usePage().props.messages?.data ?? [])
        }
    })
}

export const onSelectLang = async (newLang) => {
    await loadLanguageAsync(newLang.code)
    dayjs.locale(newLang.code)
    router.post(route('locale'), {
        locale: newLang.code
    }, {preserveScroll: true, preserveState: false})
    const storeUser = useUser();
    const {lang} = storeToRefs(storeUser);
    lang.value = newLang
}

export const onSelectCurrency = (newCurrency) => {
    router.post(route('currency'), {
        currency: newCurrency.code.toLowerCase()
    }, {preserveScroll: true, preserveState: false})
}

export const activeCurrency = computed(() => {
    return getCurrencies().find(item => item.code.toLowerCase() === usePage().props.currency.value)
})

export const normalizePriceStr = (val, precision = 2) => {
    const newVal = val.toString().replace(/\.+/g, ',')
        .replace(/,+/g, ',')
        .replace(/[^0-9,]/g, '')
    const parts = newVal.split(',')
    if (parts.length > 1 && parts[1].length > precision) {
        parts[1] = parts[1].slice(0, precision)
    }
    if (parts.length >= 2) {
        return parts[0] + ',' + parts.slice(1).join('')
    }
    return newVal
}

export const priceNumToStr = (price, precision = 2) => {
    return price.toFixed(precision).replace(/\./g, ',')
}

export const priceStrToNum = (price) => {
    const val = price.replace(/,+/g, '.')
        .replace(/\.+/g, '.')
        .replace(/[^0-9.]/g, '')
    const parts = val.toString().split('.')
    if (parts.length > 2) {
        return Number(parts[0] + '.' + parts.slice(1).join(''))
    }
    return Number(val)
}

export const getLocalStorageItem = (key) => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(key)
    }
    return null
}

export const setLocalStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem(key, value)
    }
}

export const applyTheme = () => {
    const storeUser = useUser();
    onMounted(() => {
        const theme = getLocalStorageItem('color-scheme');
        if (theme) {
            storeUser.theme = theme;
            if (typeof document !== 'undefined') document.body.classList.add(storeUser.theme);
        }
    });
}

export const reachYaGoal = (targetName) => {
    try {
        // noinspection JSUnresolvedReference
        ym(96570129, 'reachGoal', targetName)
    } catch (e) {
        console.log(e);
    }
}
