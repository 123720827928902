import {defineStore} from "pinia";
import {getLangs} from "../constants.js";
import {getActiveLanguage} from "laravel-vue-i18n";
import {setLocalStorageItem} from "@/helper.js";

export const useUser = defineStore('user', {
    state: () => {
        return {
            usersOnline: null,
            onlineChannel: null,
            lang: getLangs().find(item => item.code === getActiveLanguage()),
            theme: 'dark',
        }
    },
    getters: {
        getUserStatusByIdOrStatus: (state) => {
            return (id, status) => {
                return Boolean(state.usersOnline?.[id]) || status;
            }
        },
    },
    actions: {
        toggleTheme() {
            if (typeof document !== 'undefined') document.body.classList.remove(this.theme);
            this.theme = this.theme === 'dark' ? 'light' : 'dark';
            if (typeof document !== 'undefined') document.body.classList.add(this.theme);
            setLocalStorageItem('color-scheme', this.theme);
        }
    }
})
