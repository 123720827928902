<script setup>

import IndexLayout from "@/Layouts/IndexLayout.vue";
import Bag from "@/Components/Icons/Bag.vue";
import Coin from "@/Components/Icons/Coin.vue";
import Documents from "@/Components/Icons/Documents.vue";
import StarMain from "@/Components/Icons/StarMain.vue";
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'
import CardCategory from "@/Components/CardCategory.vue";
import CardDescription from "@/Components/CardDescription.vue";
import {usePage} from "@inertiajs/vue3";
import {route} from "ziggy-js";
import NavLink from "@/Components/NavLink.vue";
import {computed} from "vue";
import {trans} from "laravel-vue-i18n";
import PrizeDrawButton from "@/Components/Buttons/PrizeDrawButton.vue";

const page = usePage();
const slides = computed(() => [
    {
        image: page.props?.lang === 'ru' ? '/assets/img/slides/banner-gta6.webp' : '/assets/img/slides/banner-gta6-en.webp',
        alt: trans('Win GTA 6!'),
        href: route('gta-six-landing')
    },
    {
        image: page.props?.lang === 'ru' ? '/assets/img/slides/banner-brawl-stars.webp' : '/assets/img/slides/banner-brawl-stars-EN.webp',
        alt: 'Brawl Stars',
        href: route('products.show', {id: 7301602})
    },
    ...page.props?.lang === 'ru' ? [
        {
            image: '/assets/img/slides/refprogram-banner-oct24.webp',
            alt: 'Реферальная программа ANYKOD',
            href: route('referral')
        }
    ] : [],
    {
        image: page.props?.lang === 'ru' ? '/assets/img/slides/withdraw_ru.webp' : '/assets/img/slides/withdraw_en.webp',
        alt: 'Withdraw 0%',
        href: route('landing')
    },
    {
        image: page.props?.lang === 'ru' ? '/assets/img/slides/commission_ru.webp' : '/assets/img/slides/commission_en.webp',
        alt: 'Commission 0%',
        href: route('landing')
    },
    {
        image: '/assets/img/slides/banner-roblox.webp',
        alt: 'Roblox',
        href: route('products.show', {id: 7301837})
    },
    {
        image: '/assets/img/slides/banner-ps-plus.webp',
        alt: 'PS Plus',
        href: route('products.show', {id: 7301527})
    }
]);

</script>

<template>
    <IndexLayout :title="$t('HOME')">
        <Carousel :autoplay="5000" :items-to-show="1.1" :wrap-around="true">
            <Slide v-for="slide in slides" :key="slide.alt">
                <NavLink :href="slide.href" class="carousel__item bg-center bg-cover"
                         :style="`background-image: url(${slide.image})`"></NavLink>
            </Slide>
            <template #addons>
                <navigation/>
                <pagination/>
            </template>

        </Carousel>
        <div
            class="mx-auto lg:px-2 lg:py-3 pb-2 pt-3 md:pb-6 flex flex-col gap-3 px-2 sm:max-w-[39rem] md:max-w-[47rem] lg:max-w-[60rem] xl:max-w-[76rem] 2xl:max-w-[87.5rem]">
            <div class="overflow-x-auto md:overflow-x-clip hidden-scroll pb-0.5 md:pb-0 -mx-2 sm:mx-0">
                <div class="flex gap-2 px-2 sm:px-0">
                    <PrizeDrawButton class="hidden lg:flex lg:w-25 justify-center"/>
                    <CardDescription :text="$t('How to buy on AnyKod?')"
                                     :href="route('pages.show', {slug:'how-to-buy'})"
                    >
                        <template #icon>
                            <Bag class="m-auto"/>
                        </template>
                    </CardDescription>
                    <CardDescription :text="$t('How to sell on AnyKod?')"
                                     :href="route('pages.show', {slug:'how-to-sell'})"
                    >
                        <template #icon>
                            <Coin class="m-auto"/>
                        </template>
                    </CardDescription>
                    <CardDescription :text="$t('Rating transfer')"
                                     :href="route('pages.show', {slug:'rating-transfer-ru'})">
                        <template #icon>
                            <StarMain class="m-auto"/>
                        </template>
                    </CardDescription>
                    <CardDescription :text="$t('Ads transfer')"
                                     :href="route('pages.show', {slug:'adds-transfer-ru'})">
                        <template #icon>
                            <Documents class="m-auto"/>
                        </template>
                    </CardDescription>
                </div>
            </div>
            <PrizeDrawButton class="mt-3 lg:hidden w-full "/>

            <CardCategory :games="page.props.games" :is-many="false" :title="$t('Top in Games')"
                          :url="route('category', 'games')"></CardCategory>
            <CardCategory :games="page.props.subscriptions" :is-many="false" :title="$t('Top in Subscriptions')"
                          :url="route('category', 'game-subscriptions')"></CardCategory>
            <CardCategory :games="page.props.other" :is-many="true" :title="$t('Top in Other')"
                          :url="route('category', 'streaming-services')"></CardCategory>
            <CardCategory :games="page.props['game-currency']" category="game-currency" :is-many="true" :title="$t('Top in In-game currencies')"
                          :url="route('category', 'games')"></CardCategory>

        </div>
    </IndexLayout>
</template>

<style>


</style>
